import React from "react";
import {firebaseConnect} from "react-redux-firebase";
import {compose} from "redux";
import {connect}from "react-redux";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";

import i18n from '../../../i18n'

const ProductsList = (props) => {
    const products = _.map(props.products, (value, prop) => ({ id: prop, ...value }));

    const data = [];

    for (let i=0,j=products.length; i<j; i+=3) {
        data.push(products.slice(i,i+3));
    }

    const handleClick = (e, id) => {
        e.preventDefault();
        props.history.push(`product?id=${id}`);
        window.scrollTo(0, 0);

    };

    const { t } = useTranslation();
    const currentLanguage =i18n.language;

    console.log(data, "+")

  return (
        <section id="menu" data-stellar-background-ratio="0.5">
            <div className="col-md-12 col-sm-12">
                <div className="section-title wow fadeInUp" data-wow-delay="0.1s">
                    <h2>{t('our_catalog')}</h2>
                </div>
            </div>
            {data.map((e, i) => (
                <div className="container" key={i}>
                    <div className="row">
                        {e.map(product => (
                            <div className="col-md-4 col-sm-6 col-ls-3" key={product.id}  onClick={(e) => handleClick(e, product.id)}>
                                <div className="menu-thumb">
                                    <a  className="image-popup" title={product.product[currentLanguage].name}>
                                        <img src={product.imageUrl} className="img-responsive" alt=""/>
                                        <div className="menu-info">
                                            <div className="menu-item">
                                                <h3>{product.product[currentLanguage].name}</h3>
                                                <p>{product.product[currentLanguage].description}</p>
                                            </div>
                                        {/*    <div className="menu-price">*/}
                                        {/*    <span>{product.product[currentLanguage].price} {t("currency")}</span>*/}
                                        {/*</div>*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>))}
        </section>
  );
}

export default compose(
    firebaseConnect(() => ['products']),
    connect((state, props) => {
        const {
            firebase: {
                data
            }}= state;
        return {
            products: data.products
        }
    })
)(withRouter(ProductsList))
