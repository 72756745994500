import React from "react";
import {Link, useHistory} from "react-router-dom";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import {useTranslation} from "react-i18next";
import i18n from '../i18n';

const Menu = () => {
    const { t } = useTranslation();
    const {push, location} = useHistory();

    const handleClick = () => {
      if(location.pathname !== "/"){
          push('/')
      }
    }
    return (
        <section className="navbar custom-navbar navbar-fixed-top" role="navigation">
            <div className="container">

                <div className="navbar-header">
                    <button className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon icon-bar"/>
                        <span className="icon icon-bar"/>
                        <span className="icon icon-bar"/>
                    </button>

                    <Link to="/" className="navbar-brand">
                        <img className="logo" src="./logo.png" style={{width: 120, marginTop: 10}}/>
                    </Link>
                </div>

                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-nav-first">
                        <li><a
                            onClick={handleClick}
                            href="#home" className="smoothScroll">{t('menu.main')}</a></li>
                        <li><a
                            onClick={handleClick}
                            href="#about" className="smoothScroll">{t('menu.aboutUs')}</a></li>
                        <li><a
                            onClick={handleClick}
                            href="#gallery" className="smoothScroll">{t('menu.gallery')}</a></li>
                        <li><a
                            onClick={handleClick}
                            href="#menu" className="smoothScroll">{t('menu.category')}</a></li>
                        <li><a
                            onClick={handleClick}
                            href="#contact" className="smoothScroll">{t('menu.feedback')}</a></li>
                        <li>
                            <ReactFlagsSelect
                                showSelectedLabel={false}
                                countries={["AM", "RU", "US"]}
                                customLabels={{"AM": "Հայերեն","RU": "Русский","US": "English"}}
                                defaultCountry={localStorage.getItem("language") ||"AM"}
                                onSelect={(value) => {
                                    localStorage.setItem("language", value)
                                    i18n.changeLanguage(value.toLowerCase())
                                }}
                            />
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    )
};


export default Menu;
