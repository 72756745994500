import React from "react";
import "./index.css";
import {compose} from "redux";
import {firebaseConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import {withRouter, useHistory} from "react-router-dom";
import i18n from "../../i18n"
import {useTranslation} from "react-i18next";

const ProductDetails = ({currentProduct}) => {
    const history = useHistory();
    const currentLanguage = i18n.language;
    const {t} = useTranslation();
    if(!currentProduct.imageUrl){
       return null
    }
    return  <section id="home" className="slider" data-stellar-background-ratio="0.5">
        <div className="row">
            <div className="owl-carousel owl-theme owl-loaded owl-drag">
                <div className="item item-first">
                    <div className="caption">
                        <div className="container product-details">
                            <img
                                onClick={() => history.push("/")}
                                src={"https://static.thenounproject.com/png/1991936-200.png"} style={{height: 60}}/>
                            <div className="card">
                                <div className="container-fliud" style={{backgroundColor: "white", padding: 20}}>
                                    <div className="wrapper row">
                                        <div className="preview col-md-6">

                                            <div className="preview-pic tab-content">
                                                <div className="tab-pane active" id="pic-1"><img
                                                    src={currentProduct.imageUrl}/></div>
                                                <div className="tab-pane" id="pic-2"><img
                                                    src={currentProduct.imageUrl}/></div>
                                                <div className="tab-pane" id="pic-3"><img
                                                    src={currentProduct.imageUrl}/></div>
                                                <div className="tab-pane" id="pic-4"><img
                                                    src={currentProduct.imageUrl}/></div>
                                                <div className="tab-pane" id="pic-5"><img
                                                    src={currentProduct.imageUrl}/></div>
                                            </div>

                                        </div>
                                        <div className="details col-md-6">
                                            <h2 className="product-title" style={{color: "black"}}>{currentProduct.product[currentLanguage].name}</h2>
                                            <p className="product-description">{currentProduct.product[currentLanguage].description}</p>
                                            <div className="rating black">
                                                <span className="review-no">{currentProduct.product[currentLanguage].about}</span>
                                            </div>
                                            {/*<h4 className="price black">{t("price")}:  <span>{currentProduct.product[currentLanguage].price} {t("currency")}</span></h4>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default compose(
    firebaseConnect((props) => {
        const {location: {search}} = props;
        const id = search.split("=")[1];
        return ['products']
    }),
    connect((state, props) => {
        const {location: {search}} = props;
        const id = search.split("=")[1];
        const {
            firebase: {
                data
            }}= state;
        return {
            currentProduct: data.products ? data.products[id] : {}
        }
    })
)(withRouter(ProductDetails))
