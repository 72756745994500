import React from "react";
import  "./index.css"
import ImagePreview from "./preview";
import {withTranslation} from "react-i18next";




let brakePoints = [350, 1500, 1050];

const images = [
   "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F8.jpg?alt=media&token=c4f358c3-5ff5-4a39-96b6-bcc24aac9383",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F12.jpg?alt=media&token=158e6b9e-6941-487c-ab97-448f591da740",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F4.jpg?alt=media&token=caf51713-7962-4a8b-b25f-c2754e1b79c3",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F9.jpg?alt=media&token=06bbe56a-19cc-4641-ad6c-f7e90c64338c",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F79206629_571594113406869_6617312377018777600_n.jpg?alt=media&token=6ec5d9d0-4246-42ac-b7a2-21cd95a116d6",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F1.jpg?alt=media&token=582c24af-b978-4de8-b1fe-9032da2e88f5",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F6.jpg?alt=media&token=3365d6e0-cfeb-47f7-aa5c-1c6ff7c697cf",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F10.jpg?alt=media&token=69d97bbc-b00b-446c-a1e6-27880951d4bf",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F453749703_1647361662496770_8963706700654815588_n.jpg?alt=media&token=3c6e2ee8-9165-409e-94c5-381a7c42cc9a",
    "https://firebasestorage.googleapis.com/v0/b/amasia-cheese.appspot.com/o/productsImages%2F2.jpg?alt=media&token=c137a9df-b29b-4c47-9a5b-c2fbbb18adec"
]

class Gallery extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            isOpen: false,
            photoIndex: 0
        }
    }
    render(){
        const {t} = this.props;
        return (
            <section id="gallery" data-stellar-background-ratio="0.5">
            <div className="container" style={{textAlign: "center"}}>
                <h1>{t('menu.gallery')}</h1>
                <div className="masonry-container">
                    <Masonry brakePoints={brakePoints}>
                        {images.map((image, photoIndex) => {
                            return (
                                <Tile src={image} onClick={() => {
                                    this.setState({isOpen: true, photoIndex});
                                    // this.props && this.props.hideMenu()
                                }}  />
                            )
                        })}
                    </Masonry>
                </div>
                <ImagePreview images={images} {...this.state} self={this} />
            </div>
            </section>
        )
    }
}

const Tile = ({src, onClick}) => {
    return (
        <div className="tile">
            <img src={src} onClick={onClick} />
        </div>
    );
};

class Masonry extends React.Component{
    constructor(props){
        super(props);
        this.state = {columns: 1};
        this.onResize = this.onResize.bind(this);
    }
    componentDidMount(){
        this.onResize();
        window.addEventListener('resize', this.onResize)
    }

    getColumns(w){
        return this.props.brakePoints.reduceRight( (p, c, i) => {
            return c < w ? p : i;
        }, this.props.brakePoints.length) + 1;
    }

    onResize(){
        const columns = this.getColumns(this.refs.Masonry.offsetWidth);
        if(columns !== this.state.columns){
            this.setState({columns: columns});
        }

    }

    mapChildren(){
        let col = [];
        const numC = this.state.columns;
        for(let i = 0; i < numC; i++){
            col.push([]);
        }
        return this.props.children.reduce((p,c,i) => {
            p[i%numC].push(c);
            return p;
        }, col);
    }

    render(){
        return (
            <div className="masonry" ref="Masonry">
                {this.mapChildren().map((col, ci) => {
                    return (
                        <div className="column" key={ci}>
                            {col.map((child, i) => {
                                return <div key={i} >{child}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default withTranslation()(Gallery);
