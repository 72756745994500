import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/database'
import 'firebase/storage'
import {combineReducers, createStore} from "redux";
import {firebaseReducer} from "react-redux-firebase";

const fbConfig = {
    apiKey: "AIzaSyA-7W06mZ2EHikX7jDWiL0APKF_h5Iy4I0",
    authDomain: "amasia-cheese.firebaseapp.com",
    databaseURL: "https://amasia-cheese.firebaseio.com",
    projectId: "amasia-cheese",
    storageBucket: "amasia-cheese.appspot.com",
    messagingSenderId: "158990913159",
    appId: "1:158990913159:web:2ee3d81ff1d673cf7f3222",
}

firebase.initializeApp(fbConfig);


const rrfConfig = {
    userProfile: 'users'
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}


const rootReducer = combineReducers({
    firebase: firebaseReducer
    // firestore: firestoreReducer // <- needed if using firestore
})

const initialState = {};
export const store = createStore(rootReducer, initialState);

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
}
