import React from "react";

const Testimotal = () => (
    <section id="testimonial" data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
            <div className="row">

            </div>
        </div>
    </section>
);

export default Testimotal;
